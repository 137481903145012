import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de carn color violàcia amb un capell de fins a 8 cm de diàmetre, convexa al principi i pla i deprimit al centre amb l’edat. Color marró rogenc més o manco clar. El peu és cilíndric, prim, un poc dur i de color marró vinós. L’himeni està format per agulletes decurrents i molt atapeïdes, de color marró, amb el temps més fosques i de punta blanca. Les espores marró xocolata en massa, subglobuloses amb berrugues gruixudes, de 5-6 x 3,7-4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      